// font from CDN
// @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700")

 //mixins
@import "styles/mixins/fontface"

//fonts
@include fontFace('rockwell', '/assets/fonts/rockwell')
@import url(https://fonts.googleapis.com/css2?family=Jost:wght@100;300;400;500&display=swap)
@include fontFace('cera-pro', '/assets/fonts/Cera-Pro-Regular')
@include fontFace('cera-pro-black', '/assets/fonts/Cera-Pro-Black')
@include fontFace('cera-pro-thin', '/assets/fonts/Cera-Pro-Thin')

// theme
@import "../node_modules/bootstrap/scss/functions"

@import "styles/_prebootstrapvariables"
@import "styles/bootstrapvariables"
@import "styles/variables"

// vendor modules
@import "../node_modules/bootstrap/scss/bootstrap"
@import "styles/bootstrapoverwrites"

// stylesheets containers
@import "styles/containers/Root"
@import "styles/containers/App"
@import "styles/containers/Dashboard"
@import "styles/containers/FourOFour"
@import "styles/containers/Sizing"

//stylesheets components
@import "components/OfflineBar/OfflineBar"
@import "components/ScreenChapterPlayer/ScreenChapterPlayer"
@import "components/SingleDishChapter/SingleDishChapter"
@import "components/HealthyFactChapter/HealthyFactChapter"
@import "components/HealthyBenefit/HealthyBenefit"
@import "components/ProductChapter/ProductChapter"
@import "components/ProducerChapter/ProducerChapter"
@import "components/FeedbackTerminal/FeedbackTerminal"

body
  margin: 0
  padding: 0
  //font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  font-family: 'cera-pro'
  color: $black

code
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace

.btn-circle-float
  position: fixed
  bottom: 1rem
  right: 1rem
  z-index: 999
  i
    transform: translateY(15%)

h1, .h1
  font-size: 4vw
  font-size: clamp(1rem,4vw,20rem)
  font-family: 'cera-pro-black'

h2, .h2
  font-size: 2vw
  font-size: clamp(0.5rem,2vw,10rem)
  font-family: 'cera-pro-black'

h3, .h3
  font-size: 1.70vw
  font-size: clamp(0.5rem,1.70vw,14rem)

p
  font-size: 1.56vw
  font-size: clamp(0.5rem,1.56vw,9.56rem)

.small
  font-size: 1vw
  font-size: clamp(0.5rem,1vw,10rem)

.font-black
  font-family: 'cera-pro-black'

.font-thin
  font-family: 'cera-pro-thin'

.bg-blaugrau
  background-color: $c-blaugrau-90

.bg-blassgrun
  background-color: $c-blassgrun-80

.bg-tourkouis
  background-color: $c-tourkouis-80
